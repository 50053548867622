import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { PATH_TRADE, PATH_BUYER, PATH_PROJECT, PATH_RESPONDENT } from "../Routes/paths";


const Navdata = () => {
  const history = useHistory();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);

  // Apps
  const [isEmail, setEmail] = useState(false);
  const [isSubEmail, setSubEmail] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(false);
  const [isProjects, setIsProjects] = useState(false);
  const [isTasks, setIsTasks] = useState(false);
  const [isCRM, setIsCRM] = useState(false);
  const [isCrypto, setIsCrypto] = useState(false);
  const [isInvoices, setIsInvoices] = useState(false);
  const [isSupportTickets, setIsSupportTickets] = useState(false);
  const [isNFTMarketplace, setIsNFTMarketplace] = useState(false);

  // Authentication
  const [isSignIn, setIsSignIn] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isPasswordCreate, setIsPasswordCreate] = useState(false);
  const [isLockScreen, setIsLockScreen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [isVerification, setIsVerification] = useState(false);
  const [isError, setIsError] = useState(false);

  // Pages
  const [isProfile, setIsProfile] = useState(false);
  const [isLanding, setIsLanding] = useState(false);

  // Charts
  const [isApex, setIsApex] = useState(false);

  // Multi Level
  const [isLevel1, setIsLevel1] = useState(false);
  const [isLevel2, setIsLevel2] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
    if (iscurrentState !== "BaseUi") {
      setIsBaseUi(false);
    }
    if (iscurrentState !== "AdvanceUi") {
      setIsAdvanceUi(false);
    }
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    if (iscurrentState !== "Charts") {
      setIsCharts(false);
    }
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }
    if (iscurrentState !== "MuliLevel") {
      setIsMultiLevel(false);
    }
    if (iscurrentState === "Widgets") {
      history.push("/widgets");
      document.body.classList.add("twocolumn-panel");
    }
    if (iscurrentState !== "Landing") {
      setIsLanding(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
  ]);

  let menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-line",
      link: "/dashboard", // dashboard-crypto
      stateVariables: isDashboard,
    },
    {
      id: "trades",
      label: "Trades",
      icon: "ri-exchange-dollar-line",
      link: PATH_TRADE.list,
      stateVariables: isDashboard,
    },
    
    {
      id: "buyers",
      label: "Buyers",
      icon: "ri-shield-user-line",
      link: PATH_BUYER.list,
      stateVariables: isDashboard,
    },
    {
      id: "projects",
      label: "All Projects",
      icon: "ri-download-cloud-line",
      link: PATH_PROJECT.list,
      stateVariables: isDashboard,
    },
    {
      id: "markets",
      label: "Data & Pricing",
      icon: "ri-line-chart-fill",
      link: "#",  
      subItems: [ 
        {
          id: "projects",
          label: "Projects overview",
          link: "#", // new blank page
          parentId: "markets",
          className: "restricted",
        },

        {
          id: "categories",
          label: "Categories & Types",
          link: "#", // new blank page
          parentId: "markets",
          className: "restricted",
        },
        {
          id: "standards",
          label: "Standards",
          link: "#", // new blank page
          parentId: "markets",
          className: "restricted",
        },
        {
          id: "corsia",
          label: "CORSIA",
          link: "#", // new blank page
          parentId: "markets",
          className: "restricted",
        },


        
        {
          id: "buyers",
          label: "Buyers",
          link: "#", // new blank page
          parentId: "markets",
          className: "restricted",
        }, 
        {
          id: "publications",
          label: "Publications (soon!)", 
          link: "#", // new blank page
          parentId: "markets",
          className: "restricted",
        },
      ],
    },

    // {
    //   id: "respondents",
    //   label: "Respondents",
    //   icon: "ri-arrow-left-right-line",
    //   link: PATH_RESPONDENT.list,
    //   stateVariables: isDashboard,
    // },

    {
      id: "help",
      label: "Help",
      icon: "ri-shield-user-line",
      link: "https://docs.ecosystemmarketplace.com/hub/",
    },
    
  ];

  

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
