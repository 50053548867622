import { api } from "../../config";
const API_BASE_URL =  api.API_URL;
const getApiUrl = (endpoint) => API_BASE_URL + endpoint



export const URL = {
    get_tradeList: getApiUrl('/api/data/get_tradelist'), 
    get_maxTradeYear: getApiUrl('/api/data/get_maxtradeyear'),

    get_projectList: getApiUrl('/api/data/get_projectlist'), 
    get_buyerlist: getApiUrl('/api/data/get_buyerlist'),
    get_currency: getApiUrl('/api/data/get_currency'),
    get_Sdgs: getApiUrl("/api/data/projectsdgs"),
    update_project: getApiUrl("/api/data/update_project"),
    
    forget_password: getApiUrl("/api/auth/forget-password"),
    reset_password: getApiUrl("/api/auth/reset-password"),
    check_validatity: getApiUrl("/api/auth/check-validaty"),

    get_currency_rates : "https://api.exchangerate.host/latest?base=usd",
    send_new_trade_notification: getApiUrl("/api/data/send-notification"),
    send_new_bulk_trade_notification: getApiUrl("/api/data/send-bulk-trade-notification"),
    send_trade_update_or_delete_request: getApiUrl("/api/data/send-trade-update-or-delete-request"),
    add_currency_conversion_values: getApiUrl("/api/data/add-currency-conversion-values"),
    get_currency_conversion_values: getApiUrl("/api/data/get-currency-conversion-values"),
    fix_trades_with_zero_conversion_rate: getApiUrl("/api/data/fix-trades-with-zero-conversion-rate"),
    fix_trades_with_null_conversion_rate: getApiUrl("/api/data/fix-trades-with-null-conversion-rate"),
    reverse_conversion_rates: getApiUrl("/api/data/reverse-conversion-rates"),
    assign_project_id_to_trade: getApiUrl("/api/data/assign-project-id-to-trade"),
}
