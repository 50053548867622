import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  CardHeader,
  Button, 
  Row,
  Card,
  CardBody,
  Alert,
} from 'reactstrap';
import CountUp from 'react-countup';
import TradeList from './components/TradeList';
import MyPortfolio from './MyPortfolio';
import { VectorMap } from 'react-jvectormap';
import axios from 'axios';
import { api } from '../../config';
import { useSelector } from 'react-redux';


// import { Link } from 'react-router-dom';
// import BreadCrumb from '../../Components/Common/BreadCrumb';
// import MarketGraph from './MarketGraph';

// import NewsFeed from './NewsFeed';
// import RecentActivity from './RecentActivity';
// import TopPerformers from './TopPerformers';
// import Trading from './Trading';
// import Widgets from './Widgets';
// import BiChart from './BiChart';


// import DatesBarChart from './DatesBarChart';
// import DashboardLineBarChart from './DashboardLineBarChart';

const Vectormap = (props) => {
  const map = React.createRef(null);

  return (
    <>
      <div style={{ width: props.width, height: 500 }}>
        <VectorMap
          map={props.value}
          backgroundColor='transparent'
          ref={map}
          containerStyle={{
            width: '100%',
            height: '70%',
          }}
          regionStyle={{
            initial: {
              fill: '#AFC19888',
              stroke: 'none',
            },
            hover: {
              'fill-opacity': 0.8,
              cursor: 'pointer',
            },
            selected: {
              fill: '#2938bc', //what colour clicked country will be
            },
            selectedHover: {},
          }}
          series={{
            regions: props.regions,
          }}
          containerClassName='map'
        />
      </div>
    </>
  );
};

const DashboardCrypto = () => {
  const [dashboardData, setDashboardData] = useState();
  const [timetable, setTimeTable] = useState();
  const { user } = useSelector((state) => ({
    user: state.Login.user,
  }));
  const getDashboardData = async (respondent_id) => {
    if( respondent_id ){
      try {
        const response = await axios.post(
          `${api?.API_URL}/api/respondent-dashboard/`,
          { respondent_id }
        );
        setDashboardData(response);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getDate = () => {
    let date = new Date(
      dashboardData?.recent_transactions_date
    ).toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    let d = new Date(
      dashboardData?.recent_transactions_date
    ).toLocaleDateString('en-US', { day: 'numeric' });
    let compeleteDate = { day: d, monthyear: date };
    setTimeTable(compeleteDate);
  };

  useEffect(() => {
    getDate();
  }, [dashboardData]);
  
  useEffect(() => {
    if (!user?.is_superuser) getDashboardData(user?.respondent_id);
  }, []);

  document.title = 'Dashboard | Ecosystem Marketplace';
  let isViewer = false;
  if( user?.role == 3 ){
    isViewer = true;
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
          <Row>
            <Col xl={12}>
              <Card className='bg-primary'>
                <CardBody className='p-0'>
                  <Row className='align-items-end'>
                    <Col sm={10} className=''>
                      <div className='p-3 ps-4'>
                        <h4 className='display-6 fw-bold text-white'>
                          Welcome to the Global Carbon Markets Hub
                        </h4>
                        <p className='fs-16 lh-base text-white'>
                          This is the new home for all things data from
                          Ecosystem Marketplace. 
                        
                        </p>

                        <Alert color="danger">
                        
                        <p className="fs-16 lh-base">
                          <i className='ri-information-line me-2 align-middle fs-16'></i>
                          We're currently in <b>Beta</b>, so a few things to note:  
                        </p>
                        <ul className=" fs-16">
                            <li className="">Currently only trades from 2020 onwards are included.</li>
                            <li className="">If you find any <i className=' ri-bug-line align-middle fs-16 fw-bold'></i>                            
                            bugs, <a href="mailto:hub@ecosystemmarketplace.com" className='text-danger fw-bold'>please let us know</a>!</li>
                            <li className="">Please be generous with your {' '}
                              <a href="http://roadmap.ecosystemmarketplace.com/feature-requests"
                              target='_blank'
                              className='fw-bold text-danger'
                              >feedback and feature requests</a>.  </li>
                            <li className="">Help is always on hand via the menu.</li> 
                        </ul>
                        </Alert>
                      </div>
                    </Col> 
                  </Row>
                  <Alert
                    color='danger'
                    className='rounded-top alert-solid alert-label-icon border-0 rounded-0 m-0 d-flex align-items-center'>
                    <i className='ri-user-smile-line label-icon'></i>
                    <div className='flex-grow-1 text-truncate'>
                        We're a not-for-profit here to serve you and the sector. 
                        {' '}
                        <a href="http://roadmap.ecosystemmarketplace.com/feature-requests"
                         target='_blank'
                         className='fw-bold text-white'
                         >
                        Be abundant with your feature requests
                        </a>. The more there are, the better we can serve you!
                    </div>
                  </Alert>
                </CardBody>
                <CardBody className="p-0 card-border-info ">
                  <Alert color='info' className="border-0 rounded-top rounded-0 m-0 d-flex align-items-center">
                      <i className="ri-information-line me-2 fs-16"
                      />
                      <div className="flex-grow-1 text-truncate">
                      Privacy Note: data and trades presented on this screen are private per EM Respondent organization.                     
                      </div>
                  </Alert>
                </CardBody>
              </Card>
            </Col>
          </Row>
          

          { !user?.is_superuser && dashboardData && !isViewer && (
            <>
              <Row className='mt-3 mb-2'>
                <Col sm={12}>
                  <h1 className='fw-bold'>My data</h1>
                </Col>
              </Row>
              {' '}
              <Row>
                <Col sm={3}>
                  <MyPortfolio dashboardData={dashboardData} />
                </Col>
                <Col sm={9}>
                  <Row>
                    {/* <Widgets /> */}
                    <Col sm={4}>
                      <Card>
                        <CardBody>
                          <div className='d-flex align-items-center'>
                            <div className='avatar-sm flex-shrink-0'>
                              <span className='avatar-title bg-soft-info text-info rounded-circle fs-3'>
                                <i className='align-middle ri-money-dollar-circle-fill'></i>
                              </span>
                            </div>
                            <div className='flex-grow-1 ms-3'>
                              <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'>
                                Total USD Value
                              </p>
                              <h4 className=' mb-0'>
                                {' '}
                                <CountUp
                                  start={0}
                                  end={dashboardData?.trade_value_sum}
                                  decimals={0}
                                  separator=','
                                  prefix='$'
                                  duration={2}
                                />
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card>
                        <CardBody>
                          <div className='d-flex align-items-center'>
                            <div className='avatar-sm flex-shrink-0'>
                              <span className='avatar-title bg-soft-info text-info rounded-circle fs-3'>
                                <i className='align-middle ri-price-tag-2-line'></i>
                              </span>
                            </div>
                            <div className='flex-grow-1 ms-3'>
                              <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'>
                                Avg USD/TCO2e
                              </p>
                              <h4 className=' mb-0'>
                                {' '}
                                <CountUp
                                  start={0}
                                  end={dashboardData?.trade_average_price_usd}
                                  decimals={2}
                                  separator=','
                                  prefix='$'
                                  duration={2}
                                />
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card>
                        <CardBody>
                          <div className='d-flex align-items-center'>
                            <div className='avatar-sm flex-shrink-0'>
                              <span className='avatar-title bg-soft-info text-info rounded-circle fs-3'>
                                <i className='align-middle  ri-cloud-fill'></i>
                              </span>
                            </div>
                            <div className='flex-grow-1 ms-3'>
                              <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'>
                                Total Volume TCO2e
                              </p>
                              <h4 className=' mb-0'>
                                {' '}
                                <CountUp
                                  start={0}
                                  end={dashboardData?.trade_volume_sum}
                                  decimals={0}
                                  separator=','
                                  prefix=''
                                  duration={2}
                                />
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card>
                        <CardBody>
                          <div className='d-flex align-items-center'>
                            <div className='avatar-sm flex-shrink-0'>
                              <span className='avatar-title bg-soft-info text-info rounded-circle fs-3'>
                                <i className='align-middle ri-arrow-left-right-line'></i>
                              </span>
                            </div>
                            <div className='flex-grow-1 ms-3'>
                              <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'>
                                Number of Trades
                              </p>
                              <h4 className=' mb-0'>
                                {' '}
                                <CountUp
                                  start={0}
                                  end={dashboardData?.trade_count}
                                  // decimals={2}
                                  separator=','
                                  duration={2}
                                />
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card>
                        <CardBody>
                          <div className='d-flex align-items-center'>
                            <div className='avatar-sm flex-shrink-0'>
                              <span className='avatar-title bg-soft-info text-info rounded-circle fs-3'>
                                <i className='align-middle ri-leaf-fill'></i>
                              </span>
                            </div>
                            <div className='flex-grow-1 ms-3'>
                              <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'>
                                Projects traded
                              </p>
                              <h4 className=' mb-0'>
                                {' '}
                                <CountUp
                                  start={0}
                                  end={dashboardData?.unique_projects_traded}
                                  // decimals={2}
                                  separator=','
                                  duration={1}
                                />
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col sm={4}>
                      <Card>
                        <CardBody>
                          <div className='d-flex align-items-center'>
                            <div className='avatar-sm flex-shrink-0'>
                              <span className='avatar-title bg-soft-info text-info rounded-circle fs-3'>
                                <i className='align-middle ri-calendar-event-line'></i>
                              </span>
                            </div>
                            <div className='flex-grow-1 ms-3'>
                              <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'>
                                Most recent trade
                              </p>
                              <h4 className=' mb-0'>
                                {' '}
                                {/* {dashboardData?.recent_transactions_date} */}
                                {`${timetable?.day} ${timetable?.monthyear}`}
                              </h4>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    {/* <MarketGraph /> */}
                  </Row>
                  <Row>
                    <Col>
                      <Card className=''>
                        <CardHeader>
                          <h5 className='card-title mb-0 flex-grow-1'>
                            Where are the Projects you've traded?
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <div
                            id='users-by-country'
                            style={{ height: '352px' }}>
                            <Vectormap
                              value='world_mill'
                              color='#eeeeee'
                              regions={dashboardData?.group_by_country?.map(
                                (country) => {
                                  return {
                                    values: {
                                      [country?.projectid__country__iso2
                                        ? country?.projectid__country__iso2
                                        : country?.projectid__country__iso3]: 100000,
                                    }, //this is your data
                                    scale: ['#146804', '#DC6B2F'], //your color game's here
                                    normalizeFunction: 'polynomial',
                                  };
                                }
                              )}
                            />
                          </div>
                        </CardBody>
                      </Card>
                      {/* <Card className='mt-3'>
                        <CardBody>
                          <DatesBarChart dashboardData={dashboardData} />
                        </CardBody>
                      </Card>
                      <Card className='mt-3'>
                        <CardBody>
                          <DashboardLineBarChart
                            dashboardData={dashboardData}
                          />
                        </CardBody>
                      </Card> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>{/* <Widgets1 /> */}</Row>
              <Row>
                {/* <MyCurrencies /> */}
                <TradeList />
                {/* <Trading /> */}
              </Row>
            </>
          )}
          {/* <Row>
                        <RecentActivity />
                        <TopPerformers />
                        <NewsFeed />
                    </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardCrypto;
