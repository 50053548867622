import React, { useEffect } from 'react';
import Navbar from './navbar';
import Home from './home';
import Client from './client';
import Cta from './cta';
import Footer from './footer';
import { useSelector } from 'react-redux';
import Spinner from '../../Components/Common/Spinner';

const Index = (props) => {
  const loading = useSelector((state) => state?.Login?.loading);

  document.title = ' Voluntary Carbon Market Data | Ecosystem Marketplace ';

  if (loading) return <Spinner />;

  return (
    <React.Fragment>
      <div className='layout-wrapper landing'>
        <Navbar />
        <Home />
        <Client />
        <Cta />
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Index;
