import React from 'react';
import { Redirect } from 'react-router-dom';

import Landing from '../pages/Landing';

//login
import Login from '../pages/Authentication/Login';
import Requestaccess from '../pages/Authentication/Requestaccess';
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ResetPassword from '../pages/Authentication/RestPassword';

// User Profile
import UserProfile from '../pages/Authentication/user-profile';
import InvitationFrom from '../pages/Authentication/invitationFrom';
import AccountConfirm from '../pages/Authentication/AccountConfirm';

// Trade app
import Dashboard from '../pages/Dashboard';
import TradeList from '../pages/Trade/TradeList';
import BuyerList from '../pages/Buyer/BuyerList';
import ProjectList from '../pages/Project/ProjectList';
//import RespondentList from '../pages/Respondents/Respondent';
import RespondentEmployee from '../pages/RespondentEmployee/RespondentEmployee';
//import ProjectListImport from '../pages/Project/ProjectListImport';
import Utilities from '../pages/Utilities/Utilities';

// ED-Pages


// import Starter from '../pages/EdPages/Starter';
// import Trades from '../pages/EdPages/Trades';
// import Projects from '../pages/Project/Projects';
// import ProjectsAdd from '../pages/Project/ProjectAdd';

// import About from '../pages/EdPages/About';
// import Join from '../pages/EdPages/Join';
// import Docs from '../pages/EdPages/Documentation';
// import Buyer from '../pages/EdPages/Buyer';


// Analysis
//import MarketData from '../pages/MarketData';
import DataProjects from '../pages/MarketData/Projects';
import DataDashboard from '../pages/MarketData/PlatinumReportPowerBI';
//import DataPricing from '../pages/MarketData/Pricing';
//import DataTransparency from '../pages/MarketData/Transparency';
import DataCorsia from '../pages/MarketData/Corsia';
import DataStandards from '../pages/MarketData/Standards';
import DataCategories from '../pages/MarketData/Categories';
//import DataRespondents from '../pages/MarketData/Respondents';
import DataBuyers from '../pages/MarketData/Buyers'; 

// Paths
import {
  PATH_AUTH,
  PATH_TRADE,
  PATH_BUYER,
  PATH_PROJECT,
  PATH_RESPONDENT,
} from './paths';
import PageNotFound from '../pages/pageNotFound/PageNotFound';
import ProjectDetail from '../pages/Project/ProjectDetail';
//import MyRespondent from '../pages/Respondents/MyRespondent';
//import ProjectUpdate from '../pages/Project/ProjectUpdate';
//import EditTrade from '../pages/Trade/components/EditTrade';

const authProtectedRoutes = [
  //User Profile
  { path: '/profile', component: UserProfile },
  { path: '/employees/:respondent_id', component: RespondentEmployee },
  // Trade app
  { path: '/dashboard/', component: Dashboard },
  { path: PATH_TRADE.list, component: TradeList },
  // { path: '/trade/edit/:tradeId', component: EditTrade },
  { path: PATH_BUYER.list, component: BuyerList },
  { path: PATH_PROJECT.list, component: ProjectList },
  //{ path: PATH_PROJECT.import, component: ProjectListImport },
  //{ path: PATH_RESPONDENT.list, component: RespondentList },
  { path: '/project/:product_id', component: ProjectDetail },
  //{ path: '/project/edit/:product_id', component: ProjectUpdate },
  { path: '/utilities', component: Utilities },

  //{ path: '/respondent/my-own', component: MyRespondent },
  { path: '/landing/', component: Landing },
  { path: '/page-not-found', component: PageNotFound },

  // Ed-Pages
  
  // { path: '/ed-pages/starter', component: Starter },
  // { path: '/ed-pages/trades', component: Trades },
  // { path: '/ed-pages/projects', component: Projects },
  // { path: '/ed-pages/project-add', component: ProjectsAdd },
  
  // { path: '/ed-pages/about', component: About },
  // { path: '/ed-pages/join', component: Join },
  // { path: '/ed-pages/docs', component: Docs },
  // { path: '/ed-pages/buyer', component: Buyer },
  // { path: '/ed-pages/respondent', component: RespondentList },
  

  


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/dashboard' />,
  },
  // {
  //   path: '*',
  //   component: () => <Redirect to='/page-not-found' />,
  // },
];

const marketDataRoutes = [
  // Market Data
  //{ path: '/marketdata/', component: MarketData },
  { path: '/data/projects/', component: DataProjects },
  { path: '/data/categories/', component: DataCategories },
  //{ path: '/data/transparency/', component: DataTransparency },
  { path: '/data/corsia/', component: DataCorsia },
  { path: '/data/standards/', component: DataStandards },
  //{ path: '/data/respondents/', component: DataRespondents },
  { path: '/data/buyers/', component: DataBuyers },
  { path: '/data/data-dashboard/', component: DataDashboard },
];

const publicRoutes = [
  // Authentication Page
  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/landing' />,
  },
  { path: '/landing/', component: Landing },
  // CHAD AUTH
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/requestaccess', component: Requestaccess },
  { path: '/forgot-password', component: ForgetPasswordPage },
  { path: '/register', component: Register },
  { path: '/reset-password/:token', component: ResetPassword },
  { path: '/page-not-found', component: PageNotFound },
  { path: '/respondent/invite/:token', component: InvitationFrom },
  { path: PATH_AUTH.accountConfirm, component: AccountConfirm },
];

export { authProtectedRoutes, marketDataRoutes, publicRoutes };
